import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { SearchIcon } from "../../styles/icons";
import DateUtil from "../../util/DateUtil";
import { Card } from "../Card/Card";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import {
  ALL_OPTION,
  CardOrderFilterOrderStatus,
} from "./CardOrderFilterOrderStatus";
import { CardOrderOrderStatusLozenge } from "./CardOrderOrderStatusLozenge";
import { CardOrderActions } from "./CardOrderActions";
import { SearchInput } from "../SearchInput/SearchInput";
import { useSearchInputState } from "../../hooks/useSearchInputState";

interface CardSummaryProps {
  businessId: string;
}

export const CardOrderSummary = (props: CardSummaryProps) => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);
  const { query, debouncedQuery, isDebouncing, onChange, setQuery } =
    useSearchInputState("", 400);
  const [externalAccountIdSearch, setExternalAccountIdSearch] =
    useState<string>();
  const [orderStatus, setOrderStatus] = useState<{
    label: string;
    value: string;
  }>({ label: ALL_OPTION, value: ALL_OPTION });
  const [filter, setFilter] = useState<number>(0);

  const run = () => {
    setFilter(filter + 1);
  };

  const {
    items: summaries,
    isLoading: summaryLoading,
    isLoadingPage: summaryLoadingPage,
    error: summaryError,
    fetchNextPage,
    refresh,
  } = usePaginatedApiRequest(
    (apiClient) => {
      const createdFrom = DateUtil.setStartEndDate(startDate, "start");
      const createdTo = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getCardOrderSummary(
        props.businessId,
        createdFrom,
        createdTo,
        limit,
        debouncedQuery,
        externalAccountIdSearch,
        orderStatus.value === ALL_OPTION ? undefined : orderStatus.value
      );
    },
    (apiClient, continuationToken) => {
      const createdFrom = DateUtil.setStartEndDate(startDate, "start");
      const createdTo = DateUtil.setStartEndDate(endDate, "end");
      return apiClient.getCardOrderSummary(
        props.businessId,
        createdFrom,
        createdTo,
        limit,
        debouncedQuery,
        externalAccountIdSearch,
        orderStatus.value === ALL_OPTION ? undefined : orderStatus.value,
        continuationToken
      );
    },
    [filter, debouncedQuery]
  );

  const summaryRows = useMemo(
    () =>
      summaries?.map((s) => {
        const formattedOrderDate = DateUtil.getLocalDateFormatFromString(
          s.OrderDate
        );
        return (
          <Tr key={s.OrderId}>
            <Td whiteSpace="nowrap">{s.OrderId?.substring(0, 8)}</Td>
            <Td whiteSpace="nowrap">{formattedOrderDate}</Td>
            <Td whiteSpace="nowrap">{s.MembershipNumber}</Td>
            <Td whiteSpace="nowrap">{s.MemberName}</Td>
            <CardOrderOrderStatusLozenge orderStatus={s.OrderStatus} />
            <Td whiteSpace="nowrap">{s.ExternalAccountId ?? "Pending"}</Td>
            <Td whiteSpace="nowrap">{s.OrderedByUser}</Td>
            <Td whiteSpace="nowrap">
              {s.LastChange?.Item1}{" "}
              {DateUtil.getLocalDateFormatFromString(s.LastChange?.Item2)}
            </Td>
            <CardOrderActions
              businessId={s.BusinessId!!}
              cardOrderId={s.OrderId!!}
              orderStatus={s.OrderStatus!!}
              refreshTable={refresh}
            />
          </Tr>
        );
      }),
    [summaries]
  );

  return (
    <>
      <Heading size="md" alignSelf="start" pt={10}>
        Card Orders
      </Heading>
      <VStack alignItems="start" width="100%">
        <Flex className="form-filter-wrapper">
          <Box p="4">
            <FormControl>
              <FormLabel>Search</FormLabel>
              <InputGroup>
                <SearchInput
                  value={query}
                  onChange={onChange}
                  isLoading={summaryLoading || isDebouncing}
                  placeholder="Search card orders..."
                  setQuery={setQuery}
                />
              </InputGroup>
            </FormControl>
          </Box>
          <Box p="4">
            <FormControl>
              <FormLabel>External Account Id</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<SearchIcon />}
                />
                <Input
                  type="text"
                  onChange={(e) => setExternalAccountIdSearch(e.target.value)}
                />
              </InputGroup>
            </FormControl>
          </Box>
          <Box p="4">
            <FormControl>
              <FormLabel>Orders from</FormLabel>
              <Input
                type="date"
                placeholder="Orders from"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FormControl>
          </Box>
          <Box p="4">
            <FormControl>
              <FormLabel>Orders to</FormLabel>
              <Input
                type="date"
                placeholder="Orders to"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FormControl>
          </Box>
          <CardOrderFilterOrderStatus
            businessId={props.businessId}
            value={orderStatus}
            setValue={setOrderStatus}
          />
          <Box p="4">
            <FormControl>
              <FormLabel>Rows</FormLabel>
              <Select
                onChange={(e) => setLimit(parseInt(e.target.value))}
                value={limit}
                width="20"
              >
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </Select>
            </FormControl>
          </Box>
          <Box
            p="4"
            alignSelf="flex-end"
            className="form-filter-button-wrapper"
          >
            <Button colorScheme="cherryButton" onClick={run}>
              Search
            </Button>
          </Box>
        </Flex>
        <Card width="100%">
          {!summaries && summaryLoading && (
            <Center>
              <Spinner margin="4" />
            </Center>
          )}
          {summaries && !summaryLoading && (
            <Box pl="1" width="100%">
              <TableContainer alignSelf="start" width="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th whiteSpace="nowrap">Order ID</Th>
                      <Th whiteSpace="nowrap">Date</Th>
                      <Th whiteSpace="nowrap">Membership Number</Th>
                      <Th whiteSpace="nowrap">Name</Th>
                      <Th whiteSpace="nowrap">Status</Th>
                      <Th whiteSpace="nowrap">External Account ID</Th>
                      <Th whiteSpace="nowrap">Ordered By</Th>
                      <Th whiteSpace="nowrap">Last Change</Th>
                      <Th whiteSpace="nowrap"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>{summaryRows}</Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Card>
        {fetchNextPage && (
          <Box>
            {!summaryLoadingPage && (
              <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                Load more...
              </Button>
            )}
            {summaryLoadingPage && <Spinner />}
          </Box>
        )}
        {summaryError != null && (
          <ErrorMessage>
            An error was encountered while getting report.
          </ErrorMessage>
        )}
      </VStack>
    </>
  );
};
